import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import styles from "./SideBarSmall.module.css";
import Logo from "./Logo";

export default function SideBarSmall() {
  const [options, setOptions] = useState(false);
  const currentLocation = useLocation();
  const activeBar = currentLocation.pathname;
  const menuRef = useRef(null); // Reference for the menu container
  const toggleButtonRef = useRef(null); // Reference for the toggle button

  const handleMouseClick = () => setOptions((val) => !val);

  // Close the menu when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(event.target)
      ) {
        setOptions(false);
      }
    };

    // Listen for clicks or touches outside
    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);

    return () => {
      // Cleanup event listeners
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("touchstart", handleOutsideClick);
    };
  }, []);

  return (
    <div className={styles.container}>
      <Logo />
      <div
        onClick={handleMouseClick}
        className={styles.svgContainer}
        ref={toggleButtonRef} // Attach ref to the toggle button
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="52"
          height="52"
          viewBox="0 0 32 32"
          fill="none"
          stroke="#8B4513"
          strokeWidth="3"
          strokeLinecap="square"
          strokeLinejoin="miter"
          className={styles.svg}
        >
          <rect x="6" y="7" width="20" height="2" />
          <rect x="6" y="14" width="20" height="2" />
          <rect x="6" y="21" width="20" height="2" />
        </svg>
      </div>
      <ul
        ref={menuRef} // Attach the ref to the menu
        className={`${styles.menu} ${options ? styles.visible : ""}`}
      >
        <li className={activeBar === "/" ? styles.active : ""}>
          <Link to="/">בית</Link>
        </li>
        <li className={activeBar === "/calendar" ? styles.active : ""}>
          <Link to="/calendar">לוח הקורסים</Link>
        </li>
        <li
          className={activeBar === "/QuestionsAndAnswers" ? styles.active : ""}
        >
          <Link to="/QuestionsAndAnswers">שאלות שכיחות</Link>
        </li>
        <li className={activeBar === "/Refund" ? styles.active : ""}>
          <Link to="/Refund">החזר כספי</Link>
        </li>
        <li className={activeBar === "/Articles" ? styles.active : ""}>
          <Link to="/Articles">מאמרים</Link>
        </li>
        <li className={activeBar === "/recommendations" ? styles.active : ""}>
          <Link to="/recommendations">המלצות</Link>
        </li>
        <li className={activeBar === "/contact" ? styles.active : ""}>
          <Link to="/contact">צור קשר</Link>
        </li>
      </ul>
    </div>
  );
}
